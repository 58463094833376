exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cars-tsx": () => import("./../../../src/pages/cars.tsx" /* webpackChunkName: "component---src-pages-cars-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-imprint-tsx": () => import("./../../../src/pages/en/imprint.tsx" /* webpackChunkName: "component---src-pages-en-imprint-tsx" */),
  "component---src-pages-en-privacy-policy-tsx": () => import("./../../../src/pages/en/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-policy-tsx" */),
  "component---src-pages-fr-imprint-tsx": () => import("./../../../src/pages/fr/imprint.tsx" /* webpackChunkName: "component---src-pages-fr-imprint-tsx" */),
  "component---src-pages-fr-privacy-policy-tsx": () => import("./../../../src/pages/fr/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-fr-privacy-policy-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-car-purchase-request-tsx": () => import("./../../../src/pages/services/car-purchase-request.tsx" /* webpackChunkName: "component---src-pages-services-car-purchase-request-tsx" */),
  "component---src-pages-services-car-purchase-tsx": () => import("./../../../src/pages/services/car-purchase.tsx" /* webpackChunkName: "component---src-pages-services-car-purchase-tsx" */),
  "component---src-pages-services-car-service-tsx": () => import("./../../../src/pages/services/car-service.tsx" /* webpackChunkName: "component---src-pages-services-car-service-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-templates-car-financing-request-tsx": () => import("./../../../src/templates/carFinancingRequest.tsx" /* webpackChunkName: "component---src-templates-car-financing-request-tsx" */),
  "component---src-templates-car-request-tsx": () => import("./../../../src/templates/carRequest.tsx" /* webpackChunkName: "component---src-templates-car-request-tsx" */),
  "component---src-templates-car-search-tsx": () => import("./../../../src/templates/carSearch.tsx" /* webpackChunkName: "component---src-templates-car-search-tsx" */),
  "component---src-templates-car-trade-in-request-tsx": () => import("./../../../src/templates/carTradeInRequest.tsx" /* webpackChunkName: "component---src-templates-car-trade-in-request-tsx" */),
  "component---src-templates-car-tsx": () => import("./../../../src/templates/car.tsx" /* webpackChunkName: "component---src-templates-car-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/newsPost.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-trade-in-car-search-tsx": () => import("./../../../src/templates/tradeInCarSearch.tsx" /* webpackChunkName: "component---src-templates-trade-in-car-search-tsx" */)
}

