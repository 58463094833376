import { extendTheme, defineStyle, defineStyleConfig } from '@chakra-ui/react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

const brandPrimary = defineStyle({
  bg: 'brand',
  color: 'white',

  // let's also provide dark mode alternatives
  _dark: {
    bg: 'brand',
    color: 'white'
  },

  _hover: {
    bg: 'red.400',
    color: 'white',
    _dark: {
      bg: 'red.400',
      color: 'white'
    }
  }
});

const grayDark = defineStyle({
  // let's also provide dark mode alternatives
  _dark: {
    bg: 'gray.800',
    color: 'white'
  },

  _hover: {
    _dark: {
      bg: 'gray.700',
      color: 'white'
    }
  }
});

const brandTabs = defineStyle({
  tablist: {
    borderBottom: '2px solid',
    borderColor: 'inherit'
  },
  tab: {
    borderBottom: '2px solid',
    borderColor: 'transparent',
    marginBottom: '-2px',
    _selected: {
      _dark: {
        color: 'brand !important',
        borderColor: 'brand'
      }
    },
    _hover: {
      _dark: {
        color: 'red.100'
      }
    }
  }
});

const brandRadio = defineStyle({
  control: {
    _checked: {
      bg: 'brand',
      borderColor: 'brand'
    },
    _hover: {
      _checked: {
        bg: 'red.400',
        borderColor: 'brand'
      }
    },
    _focus: {
      boxShadow: 'none'
    }
  }
});

const brandStepper = defineStyle({
  indicator: {
    '&[data-status=active]': {
      borderColor: 'brand'
    },
    '&[data-status=complete]': {
      bg: 'brand'
    }
  },
  separator: {
    '&[data-status=complete]': {
      bg: 'brand'
    }
  }
});

const brandCheckbox = defineStyle({
  control: {
    _checked: {
      bg: 'brand',
      borderColor: 'brand'
    },
    _hover: {
      _checked: {
        bg: 'red.400',
        borderColor: 'brand'
      }
    },
    _focus: {
      boxShadow: 'none'
    }
  }
});

const brandSwitch = defineStyle({
  track: {
    bg: 'black',
    _checked: {
      bg: 'brand'
    }
  }
});

const brandSecondarySwitch = defineStyle({
  track: {
    bg: 'gray.700',
    _checked: {
      bg: 'brand'
    }
  }
});

const buttonTheme = defineStyleConfig({
  variants: { brandPrimary, grayDark }
});

const tabTheme = defineStyleConfig({
  variants: { brand: brandTabs }
});

const radioTheme = defineStyleConfig({
  variants: { brand: brandRadio }
});

const stepperTheme = defineStyleConfig({
  variants: { brand: brandStepper }
});

const checkboxTheme = defineStyleConfig({
  variants: { brand: brandCheckbox }
});

const switchTheme = defineStyleConfig({
  variants: { brand: brandSwitch, brandSecondary: brandSecondarySwitch }
});

const theme = {
  colors: {
    white: '#FFFAFA',
    black: '#231F20',
    brand: '#ED1C24',
    red: {
      50: '#FDE7E8',
      100: '#FABDBF',
      200: '#F69296',
      300: '#F3686D',
      400: '#F03D44',
      500: '#ED1C24',
      600: '#BD0F16',
      700: '#8E0B10',
      800: '#5F070B',
      900: '#2F0405'
    },
    gray: {
      50: '#F3F1F2',
      100: '#DDD9DA',
      200: '#C8C1C3',
      300: '#B2A8AB',
      400: '#9D9093',
      500: '#87787C',
      600: '#6C6063',
      700: '#51484A',
      800: '#363031',
      900: '#1B1819'
    }
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`
  },
  components: {
    Button: buttonTheme,
    Tabs: tabTheme,
    Radio: radioTheme,
    Stepper: stepperTheme,
    Checkbox: checkboxTheme,
    Switch: switchTheme
  }
};

export default extendTheme(theme);
